<!--
 * @Author: wyq
 * @Date: 2021-09-23 15:31:13
 * @LastEditTime: 2022-04-14 15:22:33
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\adaptiveTesting\detail.vue
-->
<template>
  <el-container class="adaptive-testing-detail">
    <el-header>
      <public-header :leftArrow="true">{{topicName}}</public-header>
    </el-header>
    <el-main>
      <v-card class="mx-auto rounded-xl">
        <color-title color="accent">
          测量进度
          <lineProgress :after="topicInfo.afterProgress"></lineProgress>
        </color-title>
        <div class="card-left">
          <tabTree :treeData="treeData" :controlRate="topicInfo.controlRate"></tabTree>
        </div>
        <div class="card-right">
          <div class="score">
            <div>
              <p>{{score || "--" }}</p>
              <p>近期能力评估</p>
            </div>
            <div>
              <p class="ranking" v-if="schoolRanking">
                前
                <span>{{schoolRanking}}%</span>
              </p>
              <p v-else>--</p>
              <p>全校排名</p>
            </div>
          </div>
        </div>
        <v-btn fab x-large color="accent" class="rounded-xl" @click="beginTest">
          <div>
            <span>开始</span>
            <br />
            <span>测试</span>
          </div>
        </v-btn>
      </v-card>
      <begin-cat-msg-box :visible.sync="dialogVisible" :name="topicName" @submit="goBeginTesting"></begin-cat-msg-box>
    </el-main>
  </el-container>
</template>
<script>
import beginCatMsgBox from '../../components/dialogs/beginCatMsgBox.vue'
import tabTree from '@/components/testing/tabTree.vue'
import lineProgress from '@/components/testing/lineProgress'
export default {
  components: {
    beginCatMsgBox,
    tabTree,
    lineProgress,
  },
  data() {
    return {
      topicId: '', //体系id
      topicName: '', //体系名称
      dialogVisible: false,
      //近期能力评估部分
      score: '', //分数
      schoolRanking: '', //校区排名
      lineMapData: [], //linemap
      treeData: [],
      topicInfo: {
        afterProgress: 0,
        controlRate: {},
      },
    }
  },
  mounted() {
    this.topicId = Number(this.$route.query.id)
    this.topicName = this.$route.query.name
    this.getTopicInfo()
  },
  methods: {
    //获取linemap数据
    getTopicInfo() {
      this.$service
        .getTopicInfo({
          topicId: this.topicId,
          subject: this.$store.state.subject,
        })
        .then((res) => {
          this.lineMapData = res.records
          if (res.records && res.records.length) {
            this.score = res.records[res.records.length - 1].score + ''
          } else {
            this.score = ''
          }
          this.schoolRanking = Math.ceil((res.allRank / res.allRankNum) * 100)
          this.treeData = res.tree
          const { measurementProgress } = res
          this.topicInfo = {
            ...res,
            beforeProgress: measurementProgress.lightUpNumPast
              ? parseInt(
                  (measurementProgress.lightUpNumPast /
                    measurementProgress.nodeNum) *
                    100
                )
              : 0,
            afterProgress: measurementProgress.lightUpNumNow
              ? parseInt(
                  (measurementProgress.lightUpNumNow /
                    measurementProgress.nodeNum) *
                    100
                )
              : 0,
          }
        })
    },
    // 点击开始测试按钮
    beginTest() {
      this.dialogVisible = true
    },
    goBeginTesting() {
      this.$router.push({
        name: 'StartCatTesting',
        query: {
          topicId: this.topicId,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.adaptive-testing-detail {
  .tab-bar {
    padding: 0;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding-bottom: 10px;
    margin-bottom: 8px;
    li {
      display: inline-block;
      height: 36px;
      margin-right: 46px;
      line-height: 36px;
      color: #999999;
      cursor: pointer;
      &.active {
        color: #111;
      }
    }
  }
  .v-card {
    position: relative;
    padding: 24px 32px;
    height: 100%;
    .card-left {
      display: inline-block;
      width: calc(100% - 140px);
      height: calc(100% - 60px);
      vertical-align: top;
    }
    .card-right {
      display: inline-block;
      width: 120px;
      vertical-align: top;
      text-align: right;
      .score {
        div {
          p:first-of-type {
            margin: 0;
            font-size: 50px;
            line-height: 60px;
            height: 60px;
            font-family: Akrobat-Black;
            color: #333;
          }
          p:nth-of-type(2) {
            margin: 0;
            font-size: 18px;
            color: #999;
          }
          .ranking {
            font-size: 20px !important;
            font-family: Akrobat-Semibold !important;
            span {
              font-size: 30px;
            }
          }
        }
      }
      .echart-container {
        margin-top: -20px;
      }
    }
    .v-btn {
      position: absolute;
      right: 60px;
      bottom: 56px;
      color: #000;
      font-size: 20px;
      box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.15);
      font-family: PingFangSC-Medium;
    }
  }
}
</style>