<!--
 * @Author: wyq
 * @Date: 2021-08-03 16:09:10
 * @LastEditTime: 2021-12-15 16:35:59
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\beginCatMsgBox.vue
-->
<template>
  <msg-box-template
    class="begin-error-test-dialog"
    :visible.sync="dialogVisible"
    title="开始测试"
    :imgUrl="require('@/assets/images/dialog/start.png')"
  >
    <p>专题：{{name}}</p>
    <p>时间：{{$store.state.catTesting.totalTime}}分钟</p>
    <p>试题：{{$store.state.catTesting.questionNum}}道</p>
    <template slot="btnGroup">
      <v-btn
        color="primary"
        depressed
        block
        x-large
        class="rounded-sm"
        @click="submit"
        :loading="submitLoadding"
      >开始测试</v-btn>
    </template>
    <span slot="tip">若中途退出考试，本次测试的内容将作废！为了保证每次测量的信度和效度，测试完成后的报告将不显示答案</span>
  </msg-box-template>
</template>
<script>
import msgBoxTemplate from './msgBoxTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    msgBoxTemplate,
  },
  mixins: [mixin],
  props: {
    name: String,
  },
}
</script>
<style lang="scss" scoped>
.begin-error-test-dialog {
  p:not(:first-of-type) {
    display: inline-block;
    width: 50%;
  }
}
</style>